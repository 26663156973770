import { ActionGameFormVariantFunction } from '@/actions/gameForm/interfaces';
import Button from '@/components/Button';
import {
  FormControlData,
  FormControlText,
  FormControlTextType,
} from '@/components/FormControlText';
import FriendlyCaptcha from '@/components/FriendlyCaptcha';
import { GameFormVariant } from '@/components/GameFormVariant';
import { Icon, IconVariant } from '@/components/Icon';
import Modal from '@/components/Modal';
import ModalLoginInfo from '@/components/ModalLoginInfo';
import ModalPasswordForgetLinks from '@/components/ModalPasswordForgetLinks';
import { VALIDATIONS } from '@/config/forms/validations';
import pushDatalayer, { DataLayerEvent } from '@/utilities/pushDatalayer';
import React from 'react';

export interface GameFormLoginProps {
  email: FormControlData;
  password: FormControlData;
  onSelect: any;
  isLoading: boolean;
  errorCode: number;
  disabled: boolean;
  isCaptchaEnabled: boolean;
  captchaRef: React.RefObject<FriendlyCaptcha>;
  callbackDone: (solution: string) => any;
  onChangeFormVariant: ActionGameFormVariantFunction;
  onChange: (id: string, value: string, isTouched?: boolean) => void;
}

const GameFormLogin: React.FC<React.PropsWithChildren<GameFormLoginProps>> = ({
  email,
  password,
  onSelect,
  isLoading,
  disabled,
  errorCode,
  isCaptchaEnabled,
  captchaRef,
  callbackDone,
  onChangeFormVariant,
  onChange,
}) => {
  return (
    <div className="GameFormLogin" tabIndex={0}>
      <div className="GameForm__container">
        <h1>Login / Anmeldung</h1>
        <h2 className="Step__header h-flex--justify-start">
          <span>
            Melden Sie sich jetzt bei Ihrer
            Landes&shy;lotterie&shy;gesell&shy;schaft an{' '}
            <Button
              ariaLabel='Weitere Informationen"'
              onClick={() => Modal.open('modal-login-info')}
            >
              <Icon variant={IconVariant.Info} />
            </Button>
          </span>
        </h2>
      </div>
      <div className="GameForm__container">
        <div className="GameForm__group">
          <label className="GameForm__label" htmlFor="email">
            E-Mail Adresse
          </label>

          <FormControlText
            id="email"
            name="email"
            type={FormControlTextType.Email}
            value={email.value}
            onChange={onChange}
            required={true}
            errorMessage={VALIDATIONS.email.message}
            isValid={email.isValid}
            isTouched={email.isTouched}
            autoComplete="email"
          />
        </div>
        <div className="GameForm__group">
          <label className="GameForm__label" htmlFor="password">
            Passwort
          </label>

          <FormControlText
            id="password"
            name="passwort"
            autoComplete="current-password"
            type={FormControlTextType.Password}
            value={password.value}
            onChange={onChange}
            required={true}
            errorMessage={VALIDATIONS.password.message}
            isValid={password.isValid}
            isTouched={password.isTouched}
          />

          <p>
            {/* FIXME */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={($event) => {
                $event.preventDefault();
                Modal.open('modal-password-forget');
              }}
            >
              Sie haben Ihr Passwort vergessen?
            </a>
          </p>
        </div>
        <div className="GameForm__group">
          <label className="GameForm__label" htmlFor="stateSelect">
            Ihr Bundesland
          </label>
          <select
            defaultValue=""
            id="stateSelect"
            onChange={onSelect}
            className="GameForm__select"
          >
            <option value="" disabled>
              Bitte wählen Sie...
            </option>
            <option value="03@lotto.de">Baden-Württemberg</option>
            <option value="02@lotto.de">Bayern</option>
            <option value="07@lotto.de">Berlin</option>
            <option value="13@lotto.de">Brandenburg</option>
            <option value="15@lotto.de">Bremen</option>
            <option value="09@lotto.de">Hamburg</option>
            <option value="05@lotto.de">Hessen</option>
            <option value="16@lotto.de">Mecklenburg-Vorpommern</option>
            <option value="04@lotto.de">Niedersachsen</option>
            <option value="01@lotto.de">Nordrhein-Westfalen</option>
            <option value="06@lotto.de">Rheinland-Pfalz</option>
            <option value="12@lotto.de">Saarland</option>
            <option value="10@lotto.de">Sachsen</option>
            <option value="11@lotto.de">Sachsen-Anhalt</option>
            <option value="08@lotto.de">Schleswig-Holstein</option>
            <option value="14@lotto.de">Thüringen</option>
          </select>
        </div>

        {errorCode && (
          <div className="FormControlText__error-message h-spacer-medium-bottom">
            {errorCode === 404 || errorCode === 400
              ? 'Ihre Anmeldedaten sind nicht korrekt. Bitte überprüfen Sie die Schreibweise. ' +
                'Alternativ können Sie hier die Registrierung durchführen.'
              : errorCode === 998
              ? 'Es ist ein Verbindungsproblem aufgetreten. Bitte versuchen Sie es auf der Webseite der Lotteriegesellschaft Ihres Bundeslandes oder morgen erneut auf LOTTO.de.'
              : errorCode === 999
              ? 'Die Sicherheitsprüfung ist fehlgeschlagen - bitte unten bei der Anti-Roboter-Verifizierung nochmal "Hier klicken" aktivieren.'
              : 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es auf der Webseite der Lotteriegesellschaft Ihres Bundeslandes oder morgen erneut auf LOTTO.de'}
          </div>
        )}

        <Button
          type="submit"
          variant={Button.Variant.Loading}
          disabled={disabled}
          isLoading={isLoading}
          fullWidth={true}
          id="login-button"
          onClick={() => {
            pushDatalayer(
              `click_element_name`,
              `Button - Anmelden`,
              DataLayerEvent.UserClick
            );
          }}
        >
          Anmelden
        </Button>

        <div className="h-separator">oder</div>

        <Button
          variant={Button.Variant.Secondary}
          disabled={isLoading}
          fullWidth={true}
          onClick={($event) => {
            $event.preventDefault();
            pushDatalayer(
              `click_element_name`,
              `Button - Ich habe noch kein Kundenkonto`,
              DataLayerEvent.UserClick
            );
            onChangeFormVariant(GameFormVariant.Register);
          }}
        >
          Ich habe noch kein Kundenkonto
        </Button>

        {isCaptchaEnabled && (
          <div className="GameForm__group">
            <label className="GameForm__label">Sicherheitsprüfung</label>
            <FriendlyCaptcha
              ref={captchaRef}
              callbackDone={callbackDone}
            ></FriendlyCaptcha>
          </div>
        )}
      </div>
      <ModalPasswordForgetLinks id="modal-password-forget" />
      <ModalLoginInfo id="modal-login-info" />
    </div>
  );
};

export default GameFormLogin;
